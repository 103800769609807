import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { envConfig } from 'shared/config'
import ReactGA from 'react-ga'

import Navigation from 'web/routing/navigation'

export const history = createBrowserHistory()

ReactGA.initialize(envConfig.TRACKING_ID, { titleCase: false })

history.listen(location => {
  ReactGA.pageview(location.pathname)
})

const AppNavigation = () => (
  <ConnectedRouter history={history}>
    <Navigation />
  </ConnectedRouter>
)
export default AppNavigation
