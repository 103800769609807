const prod = {
  ENV: 'prod',
  FIREBASE_API_KEY: 'AIzaSyAFZUKHpGNxnTOGXwo3RgYJgiYqc4PibXM',
  FIREBASE_AUTH_DOMAIN: 'whatch-407af.firebaseapp.com',
  FIREBASE_PROJECT_ID: 'whatch-407af',
  FIREBASE_STORAGE_BUCKET: 'whatch-407af.appspot.com',
  FIREBASE_DATABASE_URL: 'https://whatch-407af.firebaseio.com',
  FIREBASE_MESSAGE_SENDER_ID: '224676624474',
  TMDB_API_KEY: '7edf6d66aeb8798044b78795155cf2f4',
  MICROSERVICE_KEY: 'ThCncD3RL8P2H',
  TRACKING_ID: 'UA-135886998-2',
}

const dev = {
  ENV: 'dev',
  FIREBASE_API_KEY: 'AIzaSyAFZUKHpGNxnTOGXwo3RgYJgiYqc4PibXM',
  FIREBASE_AUTH_DOMAIN: 'whatch-407af.firebaseapp.com',
  FIREBASE_PROJECT_ID: 'whatch-407af',
  FIREBASE_STORAGE_BUCKET: 'whatch-407af.appspot.com',
  FIREBASE_DATABASE_URL: 'https://whatch-407af-518b3.firebaseio.com',
  FIREBASE_MESSAGE_SENDER_ID: '224676624474',
  TMDB_API_KEY: '7edf6d66aeb8798044b78795155cf2f4',
  MICROSERVICE_KEY: 'ThCncD3RL8P2H',
  TRACKING_ID: 'UA-135886998-1',
}

const config = () => {
  if (process.env.REACT_APP_STAGE === 'prod') return prod
  return dev
}

export default config()
