import { NAME } from './constants'

export const SIGN_IN_REQUEST = `${NAME}/SIGN_IN_REQUEST`
export const SIGN_IN_SUCCESS = `${NAME}/SIGN_IN_SUCCESS`
export const SIGN_IN_ERROR = `${NAME}/SIGN_IN_ERROR`
export const SIGN_IN_COMPLETE = `${NAME}/SIGN_IN_COMPLETE`

export const SIGN_IN_WITH_FACEBOOK_REQUEST = `${NAME}/SIGN_IN_WITH_FACEBOOK_REQUEST`
export const SIGN_IN_WITH_FACEBOOK_SUCCESS = `${NAME}/SIGN_IN_WITH_FACEBOOK_SUCCESS`
export const SIGN_IN_WITH_FACEBOOK_ERROR = `${NAME}/SIGN_IN_WITH_FACEBOOK_ERROR`

export const SIGN_IN_CANCELLED = `${NAME}/SIGN_IN_CANCELLED`

export const CLEAR_ERROR = `${NAME}/CLEAR_ERROR`

export const LINK_WITH_FACEBOOK_REQUEST = `${NAME}/LINK_WITH_FACEBOOK_REQUEST`
export const UNLINK_WITH_FACEBOOK_REQUEST = `${NAME}/UNLINK_WITH_FACEBOOK_REQUEST`

export const RESET_PASSWORD_REQUEST = `${NAME}/RESET_PASSWORD_REQUEST`
export const RESET_PASSWORD_SUCCESS = `${NAME}/RESET_PASSWORD_SUCCESS`
export const RESET_PASSWORD_ERROR = `${NAME}/RESET_PASSWORD_ERROR`

export const SIGN_UP_REQUEST = `${NAME}/SIGN_UP_REQUEST`
export const SIGN_UP_SUCCESS = `${NAME}/SIGN_UP_SUCCESS`
export const SIGN_UP_ERROR = `${NAME}/SIGN_UP_ERROR`

export const SIGN_OUT_REQUEST = `${NAME}/SIGN_OUT_REQUEST`
export const SIGN_OUT_SUCCESS = `${NAME}/SIGN_OUT_SUCCESS`
export const SIGN_OUT_COMPLETE = `${NAME}/SIGN_OUT_COMPLETE`

// --

export const DELETE_USER_REQUEST = `${NAME}/DELETE_USER_REQUEST`
export const DELETE_USER_SUCCESS = `${NAME}/DELETE_USER_SUCCESS`
export const DELETE_USER_COMPLETE = `${NAME}/DELETE_USER_COMPLETE`
export const DELETE_USER_ERROR = `${NAME}/DELETE_USER_ERROR`
