import React from 'react'
import PropTypes from 'prop-types'

import { colors } from 'web/theme'

const PageWrapper = ({ children }) => (
  <div style={styles.wrapper}>
    <div style={styles.inner}>
      {children}
    </div>
  </div>
)

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: colors.pageWrapperBg,
  },
  inner: {
    padding: 10,
  },
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageWrapper
