import React from 'react'
import { Switch, Route } from 'react-router-dom'

import routes from 'web/routing/routes'
import * as pages from 'web/pages'

export const Navigation = () => (
  <>
    <Switch>
      <Route
        exact
        path={routes.HomePage.routePath}
        component={pages.HomePage}
      />
      <Route
        exact
        path={routes.UserPage.routePath}
        component={pages.UserPage}
      />
      <Route
        exact
        path={routes.PrivacyPolicyPage.routePath}
        component={pages.PrivacyPolicyPage}
      />
      <Route component={pages.NotFoundPage} />
    </Switch>
  </>
)

export default Navigation
