import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { TextComponent } from './components'

import { selectLanguageProviderLocale } from '../../selectors'

class LanguageProviderContainer extends React.PureComponent {
  render = () => {
    const { children, locale, messages } = this.props
    return (
      <IntlProvider
        locale={locale}
        textComponent={TextComponent}
        messages={messages[locale]}
        key={locale}
      >
        {children}
      </IntlProvider>
    )
  }
}

LanguageProviderContainer.defaultProps = {
  locale: 'en',
}

LanguageProviderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string,
  messages: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  locale: selectLanguageProviderLocale(state),
})

export default connect(mapStateToProps)(LanguageProviderContainer)
