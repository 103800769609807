import { persistCombineReducers, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import LanguageProvider from 'shared/modules/LanguageProvider'

// @TODO globalise/share
import { SIGN_OUT_SUCCESS } from 'native/modules/Auth/actionTypes'

import { reducers } from './reducers'

const migrations = {
  3: state => {
    const {
      // Whatch v1 unused reducers
      Bookmarks,
      TvShows,
      Movies,
      Profiles,
      Recommendations,
      Messages,
      ViewingPlatform,
      ...rest
    } = state
    return { ...rest }
  },
}

const persistConfig = {
  key: 'App',
  blacklist: ['Navigation', 'router'],
  storage,
  version: 3,
  migrate: createMigrate(migrations, { debug: false }),
}

export const sharedReducers = {
  [LanguageProvider.constants.NAME]: LanguageProvider.reducer,
}

const appReducer = persistCombineReducers(persistConfig, {
  ...sharedReducers,
  ...reducers,
})

export const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT_SUCCESS) {
    const clearedState = {
      _persist: state._persist, // eslint-disable-line
    }
    storage.removeItem('persist:App')
    return appReducer(clearedState, action)
  }
  return appReducer(state, action)
}
