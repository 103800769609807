import * as actions from './actions'
import * as actionTypes from './actionTypes'
import * as constants from './constants'
import * as components from './components'
import * as selectors from './selectors'
import reducer from './reducer'

export default {
  actions,
  actionTypes,
  constants,
  components,
  selectors,
  reducer,
}
