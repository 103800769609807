const routes = {
  HomePage: {
    routePath: '/',
  },
  UserPage: {
    routePath: '/user',
  },
  PrivacyPolicyPage: {
    routePath: '/privacy-policy',
  },
}

export default routes
