export const isDev = process.env.NODE_ENV !== 'production'

export const loggerBlacklist = [
  'persist/REHYDRATE',
  '@@router/LOCATION_CHANGE',
  'Navigation/COMPLETE_TRANSITION',
  'Navigation/NAVIGATE',
  'Navigation/MARK_DRAWER_IDLE',
  'Navigation/DRAWER_CLOSED',
  'Navigation/MARK_DRAWER_SETTLING',
  'Navigation/OPEN_DRAWER',
  'Navigation/DRAWER_OPENED',
  '@@redux-form/CHANGE',
]

export default {
  useFixtures: false,
  ezLogin: false,
  yellowBox: false,
  reduxLogging: isDev,
  includeExamples: isDev,
}
