import { encode } from 'base-64'

export const parseBlob = response =>
  response.blob().then(parsedBlob => parsedBlob)

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.text().then(text => (text ? JSON.parse(text) : null))
}

export const shouldReturnError = (status, addErrorCodes = []) =>
  addErrorCodes.includes(status)

export const shouldParseResponse = (status, addResponseCodes = []) =>
  addResponseCodes.includes(status)

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response, resOptions) {
  if (
    (response.status >= 200 &&
      response.status < 300 &&
      !shouldReturnError(response.status, resOptions.addErrorCodes)) ||
    shouldParseResponse(response.status, resOptions.addResponseCodes)
  ) {
    return response
  }

  const error = new Error(response.statusText)
  error.response = response
  throw error
}

export const getRequestHeaders = requestOptions => {
  const requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (requestOptions.method === 'POST' || requestOptions.method === 'PUT') {
    return {
      headers: {
        ...requestHeaders,
        ...requestOptions.headers,
      },
    }
  }

  if (requestOptions.headers) {
    return {
      headers: requestOptions.headers,
    }
  }

  return null
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const request = (
  url,
  options,
  resOptions = {
    blob: false,
    addErrorCodes: [],
    addResponseCodes: [],
  },
) =>
  fetch(url, options)
    .then(res => checkStatus(res, resOptions))
    .then(res => (resOptions.blob ? parseBlob(res) : parseJSON(res)))

export const authenticatedRequest = (
  url,
  options,
  resOptions = {
    blob: false,
    addErrorCodes: [],
    addResponseCodes: [],
  },
) =>
  request(
    url,
    {
      ...options,
      headers: new Headers({
        ...(options ? options.header : null),
        Authorization: `Basic ${encode('admin:ThCncD3RL8P2H')}`,
      }),
    },
    resOptions,
  )
