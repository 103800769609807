import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { authenticatedRequest } from 'shared/utils/RequestUtils'

import whatchLogo from 'web/images/whatch-logo.svg'
import whatch from 'web/images/whatch.svg'
import iosBadge from 'web/images/ios-badge.svg'

import { colors } from 'web/theme'
import { PageWrapper, Image } from 'web/components'

const UserPage = () => {
  const [userDetails, setUserDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const { search } = useLocation()

  const searchQueries = new URLSearchParams(search)

  const getUserDetails = async uid => {
    const response = await authenticatedRequest(
      `https://whatchmicro.firebaseapp.com/get-user-public-details/${uid}`,
    )
    if (response && response.success && response.profile) {
      const { success, ...result } = response
      setUserDetails(result)
    }
    setLoading(false)
  }

  const uid = searchQueries.get('uid')

  useEffect(() => {
    if (uid) {
      getUserDetails(uid)
    }
  }, [uid])

  return (
    <PageWrapper>
      <div style={styles.contentWrapper}>
        <div style={styles.logoWrapper}>
          <Image src={whatchLogo} alt="Whatch logo" />
        </div>
        <div style={styles.whatchWrapper}>
          <Image src={whatch} alt="Whatch" />
          <h1 style={styles.hiddenTitle}>Whatch</h1>
        </div>
        <h2 style={styles.strapLine}>What to Watch</h2>
        {loading ? (
          <p style={styles.description}>Loading...</p>
        ) : (
          <div>
            {userDetails ? (
              <>
                <p style={styles.description}>
                  <strong>
                    {userDetails.profile.displayName ||
                      userDetails.profile.username}
                  </strong>{' '}
                  is on Whatch!
                </p>
                <p style={styles.description}>
                  They have{' '}
                  <strong>{userDetails.whatchListCount} titles</strong> on their
                  Whatch list, they have watched{' '}
                  <strong>{userDetails.watchedListCount} titles</strong> and{' '}
                  <strong>rated {userDetails.ratingCount}</strong> of them!
                </p>
                <p style={styles.description}>
                  You can add them as a friend and start sharing TV and Movie
                  recommendations - just Download the app and search for{' '}
                  <strong>{userDetails.profile.username}</strong>!
                </p>
              </>
            ) : (
              <p style={styles.description}>User not found...</p>
            )}
          </div>
        )}
        <h3 style={styles.download}>DOWNLOAD NOW</h3>
        <div style={styles.iosBadgeWrapper}>
          <a href="https://apps.apple.com/app/apple-store/id1387198270?pt=119075593&ct=whatch.website%20link&mt=8">
            <Image src={iosBadge} alt="Download on the App Store" />
          </a>
        </div>
        <div style={styles.badgeWrapper}>
          <a href="https://play.google.com/store/apps/details?id=com.whatch.whatch&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              width={200}
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
        </div>
      </div>
      <div style={styles.footerWrapper}>
        <a
          href="/privacy-policy"
          style={{ color: colors.link }}
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    </PageWrapper>
  )
}

const styles = {
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoWrapper: {
    width: 140,
  },
  whatchWrapper: {
    width: 160,
  },
  hiddenTitle: {
    visibility: 'hidden',
    height: 10,
    marginBottom: 0,
  },
  strapLine: {
    fontWeight: 'normal',
    fontSize: 16,
    letterSpacing: 3,
    color: colors.strapLine,
    marginBottom: 20,
  },
  description: {
    fontSize: 12,
    letterSpacing: 1.5,
    color: colors.description,
    marginBottom: 20,
    textAlign: 'center',
  },
  download: {
    fontWeight: 'bold',
    letterSpacing: 3,
    fontSize: 16,
    color: colors.download,
    marginBottom: 20,
  },
  badgeWrapper: {
    width: 200,
  },
  iosBadgeWrapper: {
    width: 174,
    marginBottom: 10,
  },
  footerWrapper: {
    marginBottom: 10,
    left: 0,
    width: '100%',
    textAlign: 'center',
  },
}

export default UserPage
