export const palette = {
  palette01: '#d85089',
  palette02: '#454e53',
  palette03: '#eff1f6',
  palette04: '#ffffff',
  palette05: '#d7d8db',
  palette06: '#f44336',
  palette07: '#000000',
  palette08: '#121212',
  palette09: '#898b8c',
  palette10: '#1d1d1d',
  palette11: '#f3f3f3',
  palette12: '#ecebeb',
  palette13: '#333333',
  palette14: '#fdf6f9',
  palette15: '#444e52',
}

export const externalPalette = {
  facebook: '#1778f2',
}

export const colors = {}
