import React from 'react'
import ReactDom from 'react-dom'

import HybridApp from './HybridApp.js'

ReactDom.render(
  <>
    <HybridApp />
  </>,
  document.getElementById('root'),
)
