import DebugConfig, { isDev } from './debugConfig'
import environmentConfig from './environmentConfig'

if (isDev) {
  console.disableYellowBox = !DebugConfig.yellowBox
}

export const envConfig = environmentConfig

export const appConfig = {
  appName: 'Whatch',
  facebookPermissions: ['public_profile', 'email'],
  sentryDns: 'https://70f3bc1f212c4b9c82944ba8b78fdcb8@sentry.io/1831866',
}
